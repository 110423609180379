body {
  background-color: rgb(248, 251, 248);
  margin: 0;
}

.dashboard-container {
  display: flex;
  height: calc(100vh - 3.7rem);
}

.split-1 {
  /* flex: 15%;
  height: 100vh; */
  height: 100vh;
  position: fixed;
  top: 0;
  left:0;
  /* overflow-y: auto; */
}

.split-2 {
  flex: 85%;
  overflow-y: auto;
  padding-left: 18%;
}


@media (max-width: 768px) {
  .split-1 {
   
    position: static; /* Remove fixed positioning */
    overflow-y: visible; 
  }
  
  .split-2 {
    padding-left: 0; /* Remove padding for smaller screens */
  }
}


@media (max-width: 1112px) {
  .split-1 {
   
    position: static; /* Remove fixed positioning */
    overflow-y: visible; 
  }
  
  .split-2 {
    padding-left: 0; /* Remove padding for smaller screens */
  }
}