.stats-row{
    display: flex;
    justify-content: center;
    margin: 2rem;
}

.stats-box{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* border: 1px solid orange; */
    margin: 1rem;
    padding: 1rem;
}

@media screen and (max-width: 600px) {

    .stats-row{
        flex-wrap: wrap;
    }
}


