.linkpool-btn{
    
    border-radius: 5px;
    padding: 0.4rem;
    color: #27ae60;
    cursor: pointer;
    transition: transform 0.3s ease;
    background-color: #e6f6ed;
}

.linkpool-btn:hover{

    transform: scale(1.0);
}


.data-para-keywords{
   
    color: rgb(47, 100, 169);
    font-size: small;
    font-weight: 600;
}

.data-para-keywords-nt{
   
    color: red;
    font-size: small;
    font-weight: 600;
}

.data-para-keywords-found{
   
    color: green;
    font-size: small;
    font-weight: 600;
}


.result-and-btn{

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.subbtn {
    color: white;
    padding:0.5rem;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    font-size: 1rem;
    background: #27ae60;
  }

  .btn-icon-text-align{
    display: flex;
    align-items: center;
    font-size: small;
    font-weight: 300;
    gap: 0.3rem;
  }


@media screen and (max-width: 818px) {

    
.result-and-btn{

    flex-direction: column;
}

    
  }
  