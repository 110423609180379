.overall-main-section{
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.forms-and-btn-container {
  
  background-color: rgb(255, 255, 255);
  padding: 1rem;
  width: 75%;
  margin-top: 2rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2rem;
  border-radius: 0.7rem;
  border: 1px solid rgb(230, 227, 227);
}


.submit-btn {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}


.input-row {
  display: flex;
  gap: 1rem;
  margin-bottom: 10px;
  
}

.form-container button {
  color: white;
  padding: 0.75rem 1.5rem;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  font-size: 1.2rem;
}


.success {
  display: flex;
  justify-content: center;
  margin-top: 0.3rem;
  color: green;
  font-weight: bold;
}

.error {
  display: flex;
  justify-content: center;
  font-size: small;
  margin-top: 0.3rem;
  color: rgb(236, 40, 40);
}

@media screen and (max-width: 1500px) {
  .two-boxes {
    flex-direction: column; /* Stack boxes vertically on smaller screens */
    gap: 1rem;
  }

  .left-box,
  .right-box {
    width: 100%; /* Take up full width on smaller screens */
  }
}

@media screen and (max-width: 768px) {
  .two-boxes {
    flex-direction: column; /* Stack boxes vertically on smaller screens */
    gap: 1rem;
  }

  .left-box,
  .right-box {
    width: 100%; /* Take up full width on smaller screens */
  }

 
}

@media screen and (max-width: 1112px) {
  .forms-and-btn-container {
    width: 95%;
  }
}



@media screen and (max-width: 553px) {
  .two-boxes {
    flex-direction: column; /* Stack boxes vertically on smaller screens */
    gap: 1rem;
  }

  .left-box,
  .right-box {
    width: 75%; /* Take up full width on smaller screens */
  }
}

