.profile-main{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3;
}


@media (max-width: 768px) {
    .profile-main{
        flex-direction: column;
    }
  }