.navbar{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 65%;
    padding: 0.4rem;
    margin: 0 auto;
    /* border: 1px solid red; */
}

.logo{
    color: rgb(0, 0, 0);
    font-size: larger;
    font-weight: bold;
    padding: 0.5rem;
}

.logo:hover{
    cursor: pointer;
}

.get-started{
    text-decoration: none;
    font-size: 1rem;
    padding: 0.4rem;
    border-radius: 4px;
    color: #ffff;
}

.get-started:hover{
    cursor: pointer;
}

.get-started-link {
    text-decoration: none;
    color: rgb(0, 0, 0);
  } 

  .mid-menu{
    display: flex;
    gap: 2rem;
  }

  .sub-mid-menu{
    color: rgb(0, 0, 0);
    font-weight: 600;
    transition: transform 0.3s ease-in-out;
  }

  .sub-mid-menu:hover{
    cursor: pointer;
    transform: scale(1.1);
  }

  /* Responsive styles using media queries */
@media (max-width: 700px) {
    .navbar {
        /* display: block; */
        flex-direction: column;
        text-align: center;
    }

    .username, .credits, .get-started {
        font-size: small;
        margin-top: 1rem;
        margin-left: 0;
        margin-right: 0;
    }
}

@media (max-width: 1580px) {

    .navbar {
        flex-direction: row;
        text-align: center;
    }

    .username, .credits, .get-started {
        margin-top: 1rem;
        margin-left: 0;
        margin-right: 0;
    }

}

@media (max-width: 545px) {
    .navbar {
        display: block;
        width: 50%;

    }

    .username, .credits, .get-started {
        font-size: small;
        margin-top: 1rem;
    }
}