.hero-section {
    width: 65%;
    height: auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 1.2rem;
    /* border: 1px solid red; */
}

.hero-title{
    text-align: center;
    font-weight:400;
    font-size: 3rem;
    margin-left: 10%;
    margin-right: 10%;
}

.hero-para-img{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6rem;
    text-align: center;
    margin-left: 5%;
    margin-right: 5%;
}

.get-started-btn-hero{  
    border: 1px solid black;
    border-radius: 5px;
    padding: 0.6rem;
    background-color: #000000;
    color: #ffff;
    transition: transform 0.3s ease-out;
    margin-bottom: 2rem;
}

.get-started-btn-hero:hover{
    cursor: pointer;
    transform: scale(1.1);
}

/* How-it-works */

.cards-in-howitowrk{
    display: flex;
    justify-content:center;
    gap: 1rem;
}

.how-card{
    padding: 1rem;
}

.how-card-title{
    display: flex;
    gap: 4px;
    color: #000000;
    font-size: larger;
    font-weight: bold;
    margin-bottom: 1rem;
}

/* homepage-main */

.homepage-main{

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}


@media screen and (max-width: 1035px) {

    .hero-section {
        width: 100%;
    }

    .hero-para-img{
        gap:2rem
    }

}

@media screen and (max-width: 600px) {

    .cards-in-howitowrk{
        flex-wrap: wrap;
    }
}
