.truncate-container {
    max-width: 100%; /* Ensure container doesn't overflow */
    word-wrap: break-word; /* Ensure words break if no space */
  }
  
  .truncate-text {
    overflow-wrap: break-word; /* Allow long words to break */
    word-wrap: break-word; /* Ensure words break if no space */
    white-space: pre-line; /* Preserve line breaks and wrap text */
  }
  