.detail-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: rgb(255, 255, 255);
  padding: 0.3rem;
  width: 75%;
  margin-left: auto;
  margin-right: auto;
  border-radius: 0.7rem;
  border: 1px solid rgb(230, 227, 227);
}

.sponsored-link-detail{
    display: flex;
    justify-content: center;
    align-items: center;
}

.sponsored-link-detail .sponsored-link-single{
  font-size: medium;

}

.links-details-both {
  display: flex;
  justify-content: center;
  gap: 2rem;
}

.left-title-availabilty-1 {
  flex: 0 0 20%;
  background-color: green;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}

.left-title-availabilty-2 { 
  flex: 0 0 20%;
  background-color: red;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}

.right-link--details {
  flex: 85%;
}

.vertical-line {
  height: 2px; /* Increase height for better visibility */
  margin: 10px 0; /* Adjust margin as needed */
  background-color: #333; /* Use a contrasting color */
  border: none;
}

.right-content{
    display: flex;
    font-size: small;
    font-weight: 600;
    border-radius: 5px;
    padding: 0.1rem;
    margin: 0.1rem;
    color: rgb(65, 63, 63);
}

@media screen and (max-width: 1300px) {

  .links-details-both{
    flex-direction: column;
  }

} 

@media screen and (max-width: 1112px) {
  .detail-container {
    width: 95%;
  }

  .backlink-details-wrap{
    word-wrap: break-word; /* Allow long words to break and wrap onto the next line */
    overflow-wrap: break-word; /* Ensure that long words break at the end of a line if needed */
  }
}
