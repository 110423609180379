.bulkcheck-result-container{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 75%;
    margin-left: auto;
    margin-right: auto;
}

.bulkcheck-result-dataset{

    width: 50%;
    flex-grow: 0;
    justify-content: center;
    align-items: center;
}

.bulkcheck-charts{
    width: 50%;
    flex-grow: 0;
    justify-content: center;
    align-items: center;
}

@media (max-width: 1010px) {

    .bulkcheck-result-container{
    flex-direction: column;
    }
}