.notification {
    position: fixed;
    top: -60px; /* Off-screen initially */
    left: 50%;
    transform: translateX(-50%);
    background-color: #de5050;
    color: #fff;
    padding: 15px;
    width: 100%;
    text-align: center;
    transition: top 0.5s ease-in-out;
  }
  
  .notification.visible {
    top: 0;
  }
  