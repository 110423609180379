.link-container {
  display: flex;
  justify-content: left;
  /* justify-content: space-between;  */
  align-items: center;
  padding: 0.3rem;
  background-color: rgba(255, 255, 255, 0.791);
  gap: 1rem;
  width: 90%;
  border-radius: 5px;
  margin-left: auto; 
  margin-right: auto;
  margin-top: 0.5rem; 
  margin-bottom: 0.5rem; 
}

.link-container:hover {
  background-color: rgb(222, 234, 222);
}



.all-links {
  background-color: rgb(255, 255, 255);
  padding: 0.3rem;
  width: 75%;
  margin-left: auto;
  margin-right: auto;
  border-radius: 0.7rem;
  border: 1px solid rgb(230, 227, 227);
}

.verticle-ops{

  margin-left: auto;
}

.verticle-ops:hover {
 cursor: pointer;
}



.sponsored-link-single{
    color: black;
    font-weight:500;
    font-size: smaller;
}

              
.loading-container{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}


.delete-btn-onshow{
  margin-left: auto;
  /* position: absolute; */
}


.pagination {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  width: 95%;
}

.pagination-info {
  color: rgb(118, 117, 117);
  line-height: 30px;
}



@media screen and (max-width: 818px) {

  .link-container{

     flex-direction: column;
     gap: 0.5rem;
     padding: 0.1rem;
  }

 
}

@media screen and (max-width: 556px) {
  .sponsored-link-single {
    margin-left: 0;
    margin-right: 0;
    max-width: 100%; /* Set a maximum width for the text */
    word-wrap: break-word; /* Allow long words to break and wrap onto the next line */
    overflow-wrap: break-word; /* Ensure that long words break at the end of a line if needed */
  }
}

@media screen and (max-width: 1112px) {
  .all-links {
    width: 95%;
  }
}
